<template>
  <div id="app">
    <MyHeader/>
    <router-view/>
    <MyFooter/>
  </div>
</template>

<script>
import MyHeader from "@/components/MyHeader.vue";
import MyFooter from "@/components/MyFooter.vue";

export default {
  name: 'myHome',
  data() {
    return {}
  },
  components: {
    MyHeader,MyFooter
  }
}
</script>

<style lang="less">
@import url("./common/css/reset.css");
#app{
  // overflow-x: hidden;
  @media only screen and (min-width: 768px){
    overflow-x: hidden;
  }
}
</style>
