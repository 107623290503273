<template>
  <div class="step-image">
    <div class="line"></div>
    <div class="step-image-inner">
      <div class="info-box margin-right">
        <img src="../assets/images/step/step-one.png" alt="">
        <div class="text">触发需求<br>预约回收</div>
      </div>
      <div class="info-box margin-right">
        <img src="../assets/images/step/step-two.png" alt="">
        <div class="text">免费上门<br>资产评估</div>
      </div>
      <div class="info-box margin-right">
        <img src="../assets/images/step/step-three.png" alt="">
        <div class="text">确定价格<br>签订合同</div>
      </div>
      <div class="info-box margin-right">
        <img src="../assets/images/step/step-four.png" alt="">
        <div class="text">验货提货<br>及时付款</div>
      </div>
      <div class="info-box margin-right">
        <img src="../assets/images/step/step-five.png" alt="">
        <div class="text">数据清理<br>安全无忧</div>
      </div>
      <div class="info-box">
        <img src="../assets/images/step/step-six.png" alt="">
        <div class="text">一级追溯<br>专业放心</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dataSafe',
  props: {  },
  data(){
    return {
      
    }
  },
  methods: {  }
}
</script>

<style scoped lang="less">
.step-image {
  background-color: #fff;
  text-align: center;
  height: 300px;
}

.step-image .line {
  border-bottom: 2px solid #00A0E9;
  margin: 115px 0 195px 0;
  width: 100%;
  position: absolute;
}

.step-image .step-image-inner {
  display: inline-block;
  width: 1200px;
  // min-width: 1200px;
  // position: absolute;
  top: 35px;
  position: relative;
  background-color: rgba(255, 255, 255, 0);
  // left: 363px;
  text-align: center;
}

.step-image .step-image-inner .info-box {
  background-color: #fff;
  display: inline-block;
  width: 148px;
  height: 148px;
}

.step-image .step-image-inner .margin-right {
  margin-right: 48px;
}

.step-image .step-image-inner .info-box .text {
  color: #898989;
  font-size: 16px;
  letter-spacing: 5px;
  font-weight: 500;
  line-height: 24px;
}
</style>