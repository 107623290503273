/**
 *   commonMixin 
 */
import { saveOfficialWebsiteIntendedCustomer } from "./api";

export const common_mixin = {
  data(){
    var validateRecycleProductType = (rule, value, callback) => {
      if(this.formData.recycleProductTypeList.length === 0) {
        callback(new Error('请选择回收产品类型'));
      } else {
        callback();
      }
    };
    var validatePhone = (rule, value, callback) => {
      let f = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/.test(value);
      let f2 = /^(?:(?:0\d{2,3})-)?(?:\d{7,8})(-(?:\d{3,}))?$/.test(value);
      if (!value) {
        callback(new Error('请输入联系方式'));
      } else if (!f && !f2) {
        return callback(new Error('联系方式格式不正确'));
      }
      callback();
    };
    return {
      loading: false,
      categyOptions: [
        { label: '笔记本回收', value: 1 },
        { label: '台式机回收', value: 2 },
        { label: '显示器回收', value: 3 },
        { label: '服务器回收', value: 4 },
        { label: '手机&平板回收', value: 5 },
        { label: '数据清除服务', value: 6 }
      ],
      rules: {
        customerName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        phone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        recycleProductType: [{ required: true, validator: validateRecycleProductType, trigger: 'change' }],
      },
    }
  },
  methods: {
    getParams(){
      let param = Object.assign({}, this.formData);
      return param;
    },
    submitForm(){
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) this.runSubmitForm();
      });
    },
    runSubmitForm(){
      let params = this.getParams();
      this.loading = true;
      saveOfficialWebsiteIntendedCustomer(params).then(res => {
        this.loading = false;
        if(res.success == true){
          this.$message.success("预约已提交,请耐心等待..");
          let datas = res.resultMap ? res.resultMap.data : null;
          // console.log(datas);
          this.resetForm();
          this.handleClose && this.handleClose();
        }
      });
    },
    resetForm(){
      // this.formData.customerName = null;
      // this.formData.phone = null;
      this.formData.recycleProductTypeList = [];
      this.$refs["ruleForm"].resetFields();
    }
  },
};