<template>
  <div class="my-footer">
    <!-- 底部  -->
    <div class="footerPage">
      <div class="footer">
        <div class="footer-inner">
          <div>
            <img src="../assets/images/footer-logo.jpg" alt="" class="footer-logo">
            <img src="../assets/images/qrcode.png" alt="" class="qrcode">
            <span class="footer-phone">400-812-7890</span><small class="footer-hot-line">（咨询热线）</small>
          </div>
          <div class="footer-record">
            <span>Copyright ©, 深圳凌瑞再生资源有限公司, All Rights Reserved 粤ICP备17130556号-1 </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->

    <!-- 右边导航图标 -->
    <div class="right-nav">
      <div class="gongzhonghao">
        <div class="gongzhonghao-nav"></div>
        <div class="gongzhonghao-big"></div>
        <div class="row"></div>
      </div>
      <div class="xiaochengxu">
        <div class="xiaochengxu-nav"></div>
        <div class="xiaochengxu-big"></div>
        <div class="row"></div>
      </div>
      <div class="top-nav" @click="scrollToTop">TOP</div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'myFooter',
  props: {
    
  },
  methods: {
    scrollToTop(){
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import url("../common/css/footer.css");
.footerPage{
  min-width: 1220px;
}
.my-footer .right-nav{
  display: inline-block;
  width: 46px;
  height: auto;
  position: fixed;
  right: 0px;
  bottom: 60px;
  z-index: 100;
  background-color: #fefefe;
  box-shadow: 0 3px 6px 0 rgba(142,138,138,.5);
  padding: 5px;
  box-sizing: border-box;
}

.my-footer .right-nav .gongzhonghao-nav{
  background-image: url("./../assets/images/navSpirite.png");
  background-position: -173px 2px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 36px;
  height: 36px;
  border-bottom: solid 1px #d7d7d7;
}

.my-footer .right-nav .xiaochengxu-nav{
  background-image: url("./../assets/images/navSpirite.png");
  background-position: -23px 0px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 34px;
  height: 36px;
  border-bottom: solid 1px #d7d7d7;
}

.my-footer .right-nav .top-nav{
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
}

.my-footer .right-nav .top-nav:hover{
  color: #489cf4;
}

.my-footer .right-nav .gongzhonghao{
  position: relative;
}

.my-footer .right-nav .gongzhonghao .gongzhonghao-big {
  position: absolute;
  display: none;
  width: 104px;
  height: 126px;
  right: 52px;
  top: -5px;
  background-image: url("./../assets/images/mini-publish.png");
  background-repeat: no-repeat;
  box-shadow: 3px 3px 6px 0 rgba(142,138,138,.5);
}

.my-footer .right-nav .gongzhonghao .row{
  position: absolute;
  top: 6px;
  right: 36px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent; 
  border-right: 8px solid transparent;
  border-left: 8px solid #fff;
  border-bottom: 8px solid transparent;
  display: none;
}

.my-footer .right-nav .gongzhonghao-nav:hover {
  background-image: url("./../assets/images/navSpirite.png");
  background-position: -302px 2px;
  background-repeat: no-repeat;
}

.my-footer .right-nav .gongzhonghao-nav:hover ~ .gongzhonghao-big{
  display: inline-block;
}
.my-footer .right-nav .gongzhonghao-nav:hover ~ .row{
  display: inline-block;
}

.my-footer .right-nav .xiaochengxu {
  position: relative;
}
.my-footer .right-nav .xiaochengxu .xiaochengxu-big {
  position: absolute;
  display: none;
  width: 104px;
  height: 126px;
  right: 52px;
  top: -5px;
  background-image: url("./../assets/images/mini-program-code.png");
  background-repeat: no-repeat;
  box-shadow: 3px 3px 6px 0 rgba(142,138,138,.5);
  background-size: 100% 100%;
}
.my-footer .right-nav .xiaochengxu .row{
  position: absolute;
  top: 6px;
  right: 36px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent; 
  border-right: 8px solid transparent;
  border-left: 8px solid #fff;
  border-bottom: 8px solid transparent;
  display: none;
}
.my-footer .right-nav .xiaochengxu-nav:hover {
  background-image: url("./../assets/images/navSpirite.png");
  background-position: 5px 1px;
  background-repeat: no-repeat;
}
.my-footer .right-nav .xiaochengxu-nav:hover ~ .xiaochengxu-big{
  display: inline-block;
}
.my-footer .right-nav .xiaochengxu-nav:hover ~ .row{
  display: inline-block;
}
</style>
