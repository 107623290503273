/**
 *  api
 */

import axios from 'axios';

const base = "https://erp.lr-amm.com/recycle_erp"; // 正式环境
// const base = 'http://192.168.30.8:8088/recycle_erp'; // 测试
const saveOfficialWebsiteIntendedCustomerUrl = base + '/customer/saveOfficialWebsiteIntendedCustomer';


export const saveOfficialWebsiteIntendedCustomer = params => { return axios.post(saveOfficialWebsiteIntendedCustomerUrl, params).then(res => res.data) };