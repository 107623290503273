<template>
  <div class="hello">
    <!-- 头部 -->
    <div class="headerPage">
      <!-- <div class="header-top">
        <div class="header-top-inner">
          <span>您好，欢迎来到小熊U享</span>
          <span class="header-top-sethome">
            <span @click="shoucang('小熊U租','http://www.lr-amm.com')" class="collect-us">收藏我们</span>
            <span @click="setHome(this,'http://www.lr-amm.com')" class="setHome">设置首页</span>
          </span>
        </div>
      </div> -->
      <div class="heade-logo">
        <div class="header-logo-inner">
          <img src="../assets/images/logo.jpg" @click="backHome" alt="">
          <span class="hot-line">
            <label>全国免费咨询热线：</label><span class="tel-phone">400-812-7890</span>
            <span class="online-appointment" @click="appointmentFn">在线预约</span>
          </span>
        </div>
      </div>
      <div class="header-nav">
        <div class="nav">
          <div :class="navClassList[0]" @click="navClick(0)">首页</div>
          <div :class="navClassList[1]" @click="navClick(1)">关于我们</div>
          <div :class="navClassList[2]" @click="navClick(2)">数据安全</div>
          <div :class="navClassList[3]" @click="navClick(3)">回收资质</div>
          <div :class="navClassList[4]" @click="navClick(4)">企业回收</div>
          <div :class="navClassList[5]" @click="navClick(5)">合作伙伴</div>
          <div :class="navClassList[6]" @click="navClick(6)">竞拍平台</div>
        </div>
      </div>
    </div>
    <!-- 头部 -->
    
    <el-dialog title="预约回收" :visible.sync="dialogVisible"  width="30%" :before-close="handleClose">
      <div class="form-data">
        <div class="appointment-dialog">预约成功后，项目经理会及时与您联系！</div>
        <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="10px">
          <el-form-item label="" prop="customerName">
            <el-input v-model="formData.customerName" placeholder="企业名称"></el-input>
          </el-form-item>
          <el-form-item label="" prop="phone">
            <el-input v-model="formData.phone" placeholder="联系电话"></el-input>
          </el-form-item>
          <el-form-item label="" prop="recycleProductType">
            <el-select v-model="formData.recycleProductTypeList" multiple clearable collapse-tags placeholder="请选择回收产品类型" style="width:100%;">
              <el-option v-for="(item,index) in categyOptions" :label="item.label" :value="item.label" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <div style="text-align:center;">
              <el-button type="primary"  @click="submitForm" :disabled="loading">立即预约</el-button>
            </div>
            <!-- <el-button>取消</el-button> -->
          </el-form-item>
        </el-form>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import { common_mixin } from "@/common/js/commonMixin";

export default {
  name: 'myHeader',
  mixins: [common_mixin],
  props: {  },
  data(){
    return {
      dialogVisible: false,
      navClassList: [
        ['each-nav','active-nav'],
        ['each-nav'],
        ['each-nav'],
        ['each-nav'],
        ['each-nav'],
        ['each-nav'],
        ['each-nav'],
      ],
      categyOptions: [
        { label: '笔记本回收', value: 1 },
        { label: '台式机回收', value: 2 },
        { label: '显示器回收', value: 3 },
        { label: '服务器回收', value: 4 },
        { label: '手机&平板回收', value: 5 },
        { label: '数据清除服务', value: 6 }
      ],
      formData: {
        customerName: null,
        phone: null,
        recycleProductTypeList: []
      },
      
    }
  },
  created(){ },
  mounted(){ },
  computed: {
    routeDatas(){
      return this.$route.path
    }
  },
  methods: {
    navClick(index){
      this.navClassList = [
        ['each-nav'],
        ['each-nav'],
        ['each-nav'],
        ['each-nav'],
        ['each-nav'],
        ['each-nav'],
        ['each-nav'],
      ];
      this.navClassList[index] = ['each-nav','active-nav'];
      this.navClassList.push([]);
      this.navClassList.pop();

      this.jumpToNav(index);
    },
    jumpToNav(index){
      switch(index){
        case 0:
          this.routesToPage("/");
          break;
        case 1:
          this.routesToPage("/abouteUs");
          break;
        case 2:
          this.routesToPage("/dataSafe");
          break;
        case 3:
          this.routesToPage("/recyclingQualification");
          break;
        case 4:
          this.routesToPage("/enterpriseRecycling");
          break;
        case 5:
          this.routesToPage("/cooperation");
          break;
        case 6:
          window.open("http://jp.lr-amm.com/index");
          break;
      }
    },
    routesToPage(url){
      let routeData = this.$route.path;
      // console.log(routeData);
      if(url !== routeData){
        this.$router.replace(url);
      }
    },
    initData(routeData){
      if(routeData === "/"){
        this.navClick(0);
        this.jumpToNav(0);
      }else if(routeData === "/abouteUs"){
        this.navClick(1);
        this.jumpToNav(1);
      }else if(routeData === "/dataSafe"){
        this.navClick(2);
        this.jumpToNav(2);
      }else if(routeData === "/recyclingQualification"){
        this.navClick(3);
        this.jumpToNav(3);
      }else if(routeData === "/enterpriseRecycling"){
        this.navClick(4);
        this.jumpToNav(4);
      }else if(routeData === "/cooperation"){
        this.navClick(5);
        this.jumpToNav(5);
      }
    },
    shoucang(sTitle,sURL){
      // console.log(window);
      try{
        window.external.addFavorite(sURL, sTitle);
      }catch (e){
        console.log(e);
        try{
            window.sidebar.addPanel(sTitle, sURL, "");
        }catch (er){
          console.log(er);
          alert("加入收藏失败，请使用Ctrl+D进行添加");
        }
      }
    },
    setHome(obj,vrl){
      try{
        obj.style.behavior='url(#default#homepage)';
        obj.setHomePage(vrl);
      }
      catch(e){
        if(window.netscape) {
          try {
            window.netscape.security.PrivilegeManager.enablePrivilege("UniversalXPConnect");
          }catch (e) {
            alert("此操作被浏览器拒绝！\n请在浏览器地址栏输入“about:config”并回车\n然后将 [signed.applets.codebase_principal_support]的值设置为'true',双击即可。");
          }
          // var prefs = Components.classes['@mozilla.org/preferences-service;1'].getService(Components.interfaces.nsIPrefBranch);
          // prefs.setCharPref('browser.startup.homepage',vrl);
        }else{
          alert("您的浏览器不支持，请按照下面步骤操作：1.打开浏览器设置。2.点击设置网页。3.输入："+vrl+"点击确定。"); 
        }              
      }
    },
    handleClose(){
      this.dialogVisible = false;
      this.$refs["ruleForm"].resetFields();
    },
    onSubmit(){},
    appointmentFn(){
      this.dialogVisible = true;
    },
    backHome(){
      this.$router.replace("/");
    }
  },
  watch: {
    routeDatas(value){
      // console.log(value);
      this.initData(value);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import url("../common/css/header.css");
.headerPage {
  min-width: 1220px;
  .collect-us:hover {
    color: #00A0E9;
  }
  .setHome:hover {
    color: #00A0E9;
  }
  .online-appointment:hover{
     filter: contrast(180%);
  }
}
.appointment-dialog {
  margin-bottom: 30px;
  text-align: center;
}
</style>
