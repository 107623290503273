<template>
  <div class="home">
    <!-- 中间内容区 -->
    <div class="contentsPage">
      <div class="big-image">
        <!-- <img src="../assets/images/index-one.png" alt=""> -->
      </div>
      <div class="step-image">
        <StepImage />
      </div>
      <div class="recycle-scene">
        <div class="recycle-scene-inner">
          <div class="recycle-scene-inner-box">
            <div class="recycle-scene-left">
              <label><img src="../assets/images/recycle-scene-title.png" alt=""></label>
              <div><img src="../assets/images/recycle-scene.png" alt=""></div>
              <div class="all-scene">
                <h4><img src="../assets/images/all-scene.png" alt=""></h4>
                <div><img src="../assets/images/data-center.png" alt=""></div>
              </div>
              <div class="recycle-category">
                <h5>核心回收品类</h5>
                <p>笔记本、台式机、服务器、工作站、一体机、显示器、手机、平板等IT办公设备</p> <br>
                <p>其他：投影仪、打印机、复印机、交换机、UPS、AP、VPN、监控、电话机、商显、网络设备等</p>
                <p>闲置办公家私、线材、机架等市场无二次流通价值的物品</p>
              </div>
            </div>
            <div class="recycle-scene-right">
              <img src="../assets/images/recycle-scene-image.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="about-xiaoxiong">
        <div class="background-img"></div>
        <div class="about-xiaoxiong-inner">
          <div class="category-info">
            <div class="each-category-info">
              <img src="../assets/images/category/note-book.png" alt="">
              <p>笔记本回收</p>
            </div>
            <div class="each-category-info">
              <img src="../assets/images/category/desktop.png" alt="">
              <p>台式机回收</p>
            </div>
            <div class="each-category-info">
              <img src="../assets/images/category/screen.png" alt="">
              <p>显示器回收</p>
            </div>
            <div class="each-category-info">
              <img src="../assets/images/category/serve.png" alt="">
              <p>服务器回收</p>
            </div>
            <div class="each-category-info">
              <img src="../assets/images/category/pad.png" alt="">
              <p>手机&平板回收</p>
            </div>
          </div>
          <div class="xiaoxiong-image">
            <img src="../assets/images/about-us.png" alt="">
          </div>
        </div>
      </div>
      <div class="about-us-text">
        <div class="about-us-text-inner">
          <p>小熊U享是小熊U租旗下的全资子公司，依托小熊U租在IT办公设备运营领域的积累和沉淀，形成二手资产回收、整备、销售的产业链闭环，主要业务包括企业电脑、服务器回收，IT资产管理以及企业数据清除等。小熊U享已与京东达成战略合作，以企业电脑回收和IT资产处置服务为宗旨为各厂商、企业、服务商提供资产管理整体解决方案与服务。
            小熊U享服务客户实现互联网巨头全覆盖，互联网Top 100企业覆盖86%以上。业务覆盖全国90多座城市，在深圳、北京、上海、广州、南京、武汉、成都、厦门等城市设有大型仓库，拥有200余人支持的服务网点及便捷快速的响应速度。</p>
        </div>
      </div>
      <div class="work-introduce">
        <div class="work-introduce-inner">
          <div class="work-introduce-box">
            <div class="work-introduce-left">
              <!-- <img v-for="(item,index) in workImagesList" :src="item.defaultImgUrl"  
                @mouseenter="imageEnter(item)" @mouseleave="imageLeave(item)" :key="'img'+index"> -->

              <div class="each-img" @mouseenter="imageEnter(0)">
                <img src="../assets/images/work/work-one.png" alt="">
                <span :class="[ !workTextList[0] ? 'mask' : null ]"></span>
              </div>
              <div class="each-img" @mouseenter="imageEnter(1)">
                <img src="../assets/images/work/work-two.png" alt="">
                <span :class="[ !workTextList[1] ? 'mask' : null ]"></span>
              </div>
              <div class="each-img" @mouseenter="imageEnter(2)">
                <img src="../assets/images/work/work-three.png" alt="">
                <span :class="[ !workTextList[2] ? 'mask' : null ]"></span>
              </div>
            </div>
            <div class="work-introduce-right">
              <div class="work-introduce-text">
                <div class="introduce-text introduce-text-one" v-show="workTextList[0]">
                  <h2>多地联营<br>全国免费上门提供一站式解决方案</h2>
                  <p>小熊U享，专注于企业二手电脑回收和IT办公设备“自收自销”企业，十余年从业经验至今，在深圳、北京、上海、广州、南京、武汉、成都、厦门、香港等多个城市设有大型仓库，无需倒卖、转包，拥有绝对的价格优势。</p>
                </div>
                <div class="introduce-text introduce-text-two" v-show="workTextList[1]">
                  <h2>采用国际<br>通行的二手电子IT产品处理标准-3R原则</h2>
                  <p>Reuse（重用） 对于几乎全新或高剩余价值的二手产品，回收后作为二手商品进入市场流通。 Recover（修复） 对于稍有破损的二手产品，专业维修团队对其进行修补并进入市场流通。 Recycle（回收） 对于剩余价值极低的产品，移交专业有资质的环保机构进行拆解和金属提炼</p>
                </div>
                <div class="introduce-text introduce-text-three" v-show="workTextList[2]">
                  <h2>凌瑞，<br>与中国知名企业相伴同行</h2>
                  <p>致力于为企业提供“安全-高价-便捷”的一站式IT设备回收服务。目前已经与京东、IBM、珍爱网等知名企业实现合作，京东战略合作伙伴。以企业电脑回收和IT资产处置服务为宗旨的凌瑞将以最优的价格、最好的服务，为各厂商、企业、服务商提供电脑回收和IT设备处置整体解决方案与服务。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="customer-profit">
        <div class="customer-profit-inner">
          <div class="customer-profit-title">
            <img src="../assets/images/customer-profit-title.png" class="profit-title-image" alt="">
            <img src="../assets/images/customer-profit.png" alt="">
          </div>
          <div class="customer-profit-nav">
            <span class="each-nav active-navp" role="one">减少库管压力<i class="arrow"></i></span><span class="each-nav" role="two">过程方便高效<i class="arrow"></i></span><span class="each-nav" role="three">数据安全无忧<i class="arrow"></i></span><span class="each-nav" role="four">成就企业责任<i class="arrow"></i></span>
          </div>
          <div>
            <div class="customer-profit-content customer-profit-one">
              <div class="customer-profit-content-left">
                <p>小熊U享致力于为企业客户提供覆盖IT设备的全生命周期的服务，涵盖IT设备全品类，通过专业的解决方案帮助企业废旧资产变现，显著减轻企业自身的库存管理与成本，让企业轻资产运作，资金能快速回拢并充分使用，增大资金回报率。</p>
              </div>
              <div class="customer-profit-content-right">
                <img src="../assets/images/customerprofit/customer-store.png" alt="">
              </div>
            </div>
            <div class="customer-profit-content customer-profit-two">
              <div class="customer-profit-content-left">
                <p>专属服务项目经理1对1为客户提供最便捷最高效的回收服务，集中、分散式上门服务等完善的系统解决方案，跟踪协调全程的每个环节，加快企业领导层决策，让企业减少不必要的精力投入。</p>
              </div>
              <div class="customer-profit-content-right">
                <img src="../assets/images/customerprofit/delivery.png" alt="">
              </div>
            </div>
            <div class="customer-profit-content customer-profit-three">
              <div class="customer-profit-content-left">
                <p>与全球领先的数据安全公司Blancco战略合作，为企业提供国防级数据安全清除服务（可上门提供数据清除服务）。支持本地和远程部署同时从多个硬盘/固态硬盘中永久擦除数据，保证数据不可逆的安全，可提供数据擦除证明，彻底杜绝企业敏感信息泄露风险，让企业高枕无忧。</p>
              </div>
              <div class="customer-profit-content-right">
                <img src="../assets/images/customerprofit/data-safe.png" alt="">
              </div>
            </div>
            <div class="customer-profit-content customer-profit-four">
              <div class="customer-profit-content-left">
                <p>IT资产循环再用建立在资源回收和循环再利用基础上的经济发展模式，整个流通过程绿色环保、节能减排的理念始终贯穿其中，，通过IT资源高效的循环利用，实现污染的低排放甚至零排放，保护环境，遵循国家发展循环经济建设节约型社会的目标，助力企业客户碳减排，无公害处理，彰显企业社会责任(CSR).</p>
              </div>
              <div class="customer-profit-content-right">
                <img src="../assets/images/customerprofit/cooperation.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="achieve-customer">
        <div class="achieve-customer-inner">
          <div class="achieve-customer-title">
            <img src="../assets/images/achieve-customer-title.png" class="achieve-customer-title-img" alt="">
            <img src="../assets/images/achieve-customer.png" alt="">
          </div>
          <div class="achieve-customer-image">
            <img src="../assets/images/achieve-customer-list.png" alt="">
          </div>
        </div>
      </div>
      <div role="form-data">
        <FormData />
      </div>
    </div>
    <!-- 中间内容区 -->
    
  </div>
</template>

<script>
/* eslint-disable*/ 
// import '@/common/js/jquery-3.6.0.min.js';
import $ from "jquery";
// import workOneImg from "../assets/images/work/work-one.png";
// import workTwoImg from "../assets/images/work/work-two.png";
// import workThreeImg from "../assets/images/work/work-three.png";
import StepImage from "../components/StepImage.vue";
import FormData from "../components/FormData.vue";

export default {
  name: 'HomeView',
  data(){
    return {
      // workImagesList: [
      //   { imgUrl: workOneImg, id: 1 },
      //   { imgUrl: workTwoImg, id: 2 },
      //   { imgUrl: workThreeImg, id: 3 },
      // ],
      workTextList: [ true, false, false ],
    }
  },
  components: { StepImage,FormData },
  mounted(){
    $(function(){
      $(".arrow").hide();
      $(".arrow").first().show();
      $(".customer-profit-content").hide();
      $(".customer-profit-content").first().show();
      $(".each-nav").hover(function (){
        $(this).addClass("active-navp").siblings().removeClass("active-navp");
        $(this).children(".arrow").show();
        $(this).siblings().children(".arrow").hide();
        $(".customer-profit-"+ $(this).attr("role")).siblings().hide();
        $(".customer-profit-"+ $(this).attr("role")).show();
      });
    });
  },
  methods: {
    imageEnter(index){
      // console.log("imageHover");
      this.workTextList = [false,false,false];
      this.workTextList[index] = true;
      this.workTextList.push(false);
      this.workTextList.pop();
    },
    imageLeave(){
      // console.log("leave");
    },
  }
}
</script>

<style scoped lang="less">
@import url("../common/css/index.css");
.work-introduce {
  .each-img {
    position: relative;
    display: inline-block;
    width: 238px;
    height: 399px;
    .mask {
      left: 0;
      top: 0;
      display: inline-block;
      width: 238px;
      height: 399px;
      position: absolute;
      background-color: rgba(0,160,233,.6);
    }
  }
}

</style>
