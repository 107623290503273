<template>
  <div role="formData">
    <div class="form-data">
      <div class="line"></div>
      <div class="form-data-inner">
        <img src="../assets/images/user-logo.png" class="user-logo" alt="">
        <div class="form-title">
          <img src="../assets/images/recovery-appoint.png" alt="">
        </div>
        <div class="form-sub-title">预约成功后，项目经理会及时与您联系上门回收</div>
        <div class="form">
          <div class="form-input">
            <el-form :inline="true" :model="formData" :rules="rules" ref="ruleForm" class="demo-form-inline">
              <el-form-item label="" prop="customerName">
                <el-input v-model="formData.customerName" placeholder="企业名称"></el-input>
              </el-form-item>
              <el-form-item prop="phone">
                <el-input v-model="formData.phone" placeholder="联系电话"></el-input>
              </el-form-item>
              <el-form-item label="" prop="recycleProductType">
                <el-select v-model="formData.recycleProductTypeList" multiple clearable collapse-tags placeholder="请选择回收产品类型">
                  <el-option v-for="(item,index) in categyOptions" :label="item.label" :value="item.label" :key="index"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="submit" @click="submitForm" :disabled="loading">立即预约</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { common_mixin } from "@/common/js/commonMixin";

export default {
  name: 'formData',
  props: {  },
  mixins: [common_mixin],
  data(){
    return {
      formData: {
        customerName: null,
        phone: null,
        recycleProductTypeList: []
      },
    }
  },
  components : {  },
  methods: {  }
}
</script>

<style scoped lang="less">
.form-data {
  margin-top: 32px;
  // background-image: url("../assets/images/circle-background.png");
  // background-repeat: no-repeat;
  // background-position: 0% -4%;
  text-align: center;
}

.form-data .form-data-inner {
  display: inline-block;
  width: 1200px;
  // min-width: 1200px;
  position: relative;
}

.form-data .line {
  border-bottom: 2px solid #FAFAFA;
}

.form-data .user-logo {
  position: absolute;
  top: -32px;
  left: 48%;
}

.form-data .form-data-inner .form-title {
  margin-top: 56px;
  background-image: url("../assets/images/user-background.png");
  background-repeat: no-repeat;
  background-position: center;
}

.form-data .form-data-inner .form-title>img{
  margin-top: 4px;
}

.form-data .form-data-inner .form-sub-title {
  font-size: 14px;
  font-weight: 400;
  color: #727171;
  line-height: 85px;
  margin-top: 25px;
}

.form-data .form-data-inner .form {
  margin-bottom: 112px;
}

.form-data .form-data-inner .form .form-input {
  margin-bottom: 44px;
}

.form-data .form-data-inner .form .form-input>input {
  width: 281px;
  height: 34px;
  text-indent: 20px;
  margin-right: 43px;
  outline: none;
  border: 1px solid #898989;
}

.form-data .form-data-inner .form .form-input>select {
  width: 281px;
  text-indent: 20px;
  height: 40px;
  outline: none;
  border: 1px solid #898989;
  position:relative;
  color: #666;
}

.form-data .form-data-inner .form .form-input>select option {
  color: #666;
}

.form-data .form-data-inner .form .submit {
  background-image: url("../assets/images/submit-btn.png");
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  width: 343px;
  height: 36px;
  line-height: 36px;
  color: #ffffff;
  cursor: pointer;
}
</style>