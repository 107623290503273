import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '小熊U享-首页'
    },
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MyCooperation.vue'),
    meta: {
      title: '小熊U享-合作伙伴'
    },
  },
  {
    path: "/recyclingQualification",
    name: "recyclingQualification",
    component: () => import("../views/RecyclingQualification.vue"),
    meta: {
      title: '小熊U享-回收资质'
    },
  },
  {
    path: "/dataSafe",
    name: "dataSafe",
    component: () => import("../views/DataSafe.vue"),
    meta: {
      title: '小熊U享-数据安全'
    },
  },
  {
    path: "/enterpriseRecycling",
    name: "enterpriseRecycling",
    component: () => import("../views/EnterpriseRecycling.vue"),
    meta: {
      title: '小熊U享-企业回收'
    },
  },
  {
    path: "/abouteUs",
    name: "abouteUs",
    component: () => import("../views/AbouteUs.vue"),
    meta: {
      title: '小熊U享-关于我们'
    },
  },
  {
    path: "/cooperationServeType",
    name: "cooperationServeType",
    component: () => import("../views/CooperationServeType.vue"),
    meta: {
      title: '小熊U享-合作伙伴-服务型'
    },
  },
  {
    path: "/cooperationType",
    name: "cooperationType",
    component: () => import("../views/CooperationType.vue"),
    meta: {
      title: '小熊U享-合作伙伴-合作型'
    },
  },
  {
    path: "/cooperationPublicWelfare",
    name: "cooperationPublicWelfare",
    component: () => import("../views/CooperationPublicWelfare.vue"),
    meta: {
      title: '小熊U享-合作伙伴-公益型'
    },
  },

]

const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes
})

export default router
